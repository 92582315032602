import { Injectable, inject } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { tap, concatMap, finalize } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class LoadingService {
	public loadingSubject = new BehaviorSubject<boolean>(false);
	loading$: Observable<boolean> = this.loadingSubject.asObservable();

	showLoaderUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
		return of(null).pipe(
			tap(() => this.loadingOn()),
			concatMap(() => obs$),
			finalize(() => this.loadingOff()),
		);
	}

	loadingOn(showSpinner?: boolean) {
		if (showSpinner) {
			// this.ngxSpinnerService.show();
		}
		this.loadingSubject.next(true);
	}

	loadingOff(hideSpinner?: boolean) {
		if (hideSpinner) {
			// this.ngxSpinnerService.hide();
		}
		this.loadingSubject.next(false);
	}
}
