import { Routes } from "@angular/router";
import { authGuard } from "./authentication/_services/auth.guard";
import { FullLayoutComponent } from "./share/layouts/full-layout/full-layout.component";


export const APP_ROUTES: Routes = [
	{
		path: "",
		canActivate: [authGuard],
		component: FullLayoutComponent,
		children: [
			{
				path: "",
				redirectTo: "claims",
				pathMatch: "full",
			},
			{
				path: "claims",
				loadChildren: () =>
					import("./claims/claims-routing.module").then((r) => r.CLAIMS_ROUTES),
			}
		],
	},
	{
		path: "authentication",
		loadChildren: () =>
			import("./authentication/authentication-routing.module").then(
				(r) => r.AUTH_ROUTES,
			),
	},
	{
		path: "**",
		redirectTo: "authentication/404",
	},
];
