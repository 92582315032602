<mat-toolbar class="header-container">
	<div class="header-container__menu">
		<div class="header-container__menu__first">
			<button mat-icon-button (click)="drawerService.toggle()">
				<mat-icon>menu</mat-icon>
			</button>
	
			<img src="https://storage.googleapis.com/dev-ps-asset/Car_Insurance/ps-logo.png" class="ps-logo" />
		</div>

		<mat-chip-listbox *ngIf="userProfile">
			<mat-chip
				[matMenuTriggerFor]="menu"
				style="font-weight: 600; font-size: 16px"
			>
				{{ userProfile?.name }}
			</mat-chip>
		</mat-chip-listbox>

		<mat-menu #menu="matMenu">
			<button mat-menu-item (click)="authService.logout()">Logout</button>
		</mat-menu>
	</div>
</mat-toolbar>
