import { Injectable, inject } from "@angular/core";
import { MatBottomSheet } from "@angular/material/bottom-sheet";
import { jwtDecode } from "jwt-decode";
import { ChangePasswordComponent } from "../../share/components/dialogs/change-password/change-password.component";

const TOKEN_KEY = "token";

@Injectable({
	providedIn: "root",
})
export class AuthService {
	private bottomSheet = inject(MatBottomSheet);

	setToken(token: string) {
		document.cookie = `${TOKEN_KEY}=${token};path=/;`;
	}

	getToken() {
		return this.getCookie(TOKEN_KEY);
	}

	getUserId() {
		const token = this.getToken();
		const decoded = jwtDecode(token);
		return decoded[
			"http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
		];
	}

	isTokenExpired(): boolean {
		const token = this.getToken();
		if (!token) return false;
		const decoded = jwtDecode(token);
		const expireTime = new Date(decoded["exp"] * 1000);

		return expireTime < new Date();
	}

	isLoggedIn(): boolean {
		const token = this.getToken();
		const isTokenExpired = this.isTokenExpired();

		return token && !isTokenExpired ? true : false;
	}

	logout() {
		document.cookie = `${TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
		window.location.reload();
	}

	changePassword() {
		const dialog = this.bottomSheet.open(ChangePasswordComponent, {
			backdropClass: "sidenav-container",
			// data: {
			// },
		});

		dialog.afterDismissed().subscribe((x) => {
			if (x) {
			}
		});
	}

	private getCookie(cname: string): string | null {
		const name = `${cname}=`;
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookieValues = decodedCookie.split(";");

		for (const cookieValue of cookieValues) {
			const trimmedValue = cookieValue.trim();
			if (trimmedValue.startsWith(name)) {
				return trimmedValue.substring(name.length);
			}
		}

		return null;
	}
}
