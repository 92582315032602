import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { LoadingSpinnerComponent } from './share/components/loading-spinner/loading-spinner.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
	imports: [RouterOutlet, LoadingSpinnerComponent],
})
export class AppComponent {
  title = 'maxis-tpa-portal';
}
