import { environment } from "src/environments/environment";

const userBaseUrl = `${environment.url}/api/TPAUser/`;
const baseUrl = `${environment.url}/api/TPAClaim/`;

export const ENDPOINTS = {
	user: {
		login: `${userBaseUrl}Login`,
		getProfile: `${userBaseUrl}GetProfile`,
	},
	claim: {
		getListing: `${baseUrl}GetListing`,
		getListingExcel: `${baseUrl}GetListingExcel`,
		getDetailById: `${baseUrl}GetDetailById/`,
		updateStatus: `${baseUrl}UpdateClaimStatus/`,
		getInspectionReportDetailById: `${baseUrl}GetInspectionReportDetailById/`,
		downloadInspectionReportById: `${baseUrl}DownloadInspectionReportDocumentById/`,
		downloadClaimDocuments: `${baseUrl}DownloadClaimDocuments/`,
	},
};
