import {
	AfterContentChecked,
	Component,
	HostListener,
	ViewChild,
} from "@angular/core";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { UserService } from "../../../authentication/_services/user.service"; 
import { DrawerService } from "../../services/drawer.service";
import { take } from "rxjs/operators";
import { RouterOutlet } from "@angular/router";
import { AppHeaderComponent } from "../header/header.component";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { AppDrawerComponent } from "../drawer/drawer.component";

@Component({
    selector: "app-full-layout",
    templateUrl: "./full-layout.component.html",
    styleUrls: ["../full-layout.scss"],
    standalone: true,
    imports: [
        MatSidenavModule,
        AppDrawerComponent,
        CdkScrollable,
        AppHeaderComponent,
        RouterOutlet,
    ],
})
export class FullLayoutComponent implements AfterContentChecked {
	@ViewChild("drawer") public drawer: MatDrawer;
	screenWidth: number;
	constructor(
		private drawerService: DrawerService,
		public userService: UserService,
	) {
		this.screenWidth = window.innerWidth;

		this.userService.getProfile().pipe(take(1)).subscribe();
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.screenWidth = event.target.innerWidth;
	}
	ngAfterContentChecked(): void {
		this.drawerService.drawer = this.drawer;
	}
}
