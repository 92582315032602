import { Injectable, inject } from "@angular/core";
import { UserProfile } from "../../share/interfaces/user.interface";
import { Observable, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { ENDPOINTS } from "../../share/endpoints";
import { catchError, tap } from "rxjs/operators";
import { AlertService } from "../../share/services/alert.service";

@Injectable({
	providedIn: "root",
})
export class UserService {
	private http = inject(HttpClient);
	private alertService = inject(AlertService);

	user!: UserProfile;

	login(email: string, password: string, type: string) {
		return this.http
			.post(
				ENDPOINTS.user.login,
				{ email, password, type },
				{ responseType: "text" },
			)
			.pipe(
				catchError((err) => {
					const message = "Failed to login";
					console.error(message, err);
					this.alertService.openSnackBar(message);
					return throwError(() => err);
				}),
			);
	}

	getProfile(): Observable<UserProfile> {
		return this.http
			.get<UserProfile>(ENDPOINTS.user.getProfile)
			.pipe(
				tap((res) => {
					this.user = res;
				}),
			)
			.pipe(
				catchError((err) => {
					const message = "Failed to get user info";
					console.error(message, err);
					this.alertService.openSnackBar(message);
					return throwError(() => err);
				}),
			);
	}

	// createUser(
	// 	name: string,
	// 	email: string,
	// 	motorPartnerGroupPartnerIds: string[],
	// 	password: string,
	// ): Observable<any> {
	// 	return this.http.post<any>(ENDPOINTS.user.addUser, {
	// 		email,
	// 		name,
	// 		motorPartnerGroupPartnerIds,
	// 		password,
	// 	});
	// }

	// editUser(
	// 	id: string,
	// 	name: string,
	// 	email: string,
	// 	motorPartnerGroupPartnerIds: string[],
	// 	password: string,
	// ): Observable<any> {
	// 	return this.http.post<any>(ENDPOINTS.user.updateUserDetails + id, {
	// 		name,
	// 		email,
	// 		motorPartnerGroupPartnerIds,
	// 		password,
	// 	});
	// }

	// removeUser(id: string): Observable<any> {
	// 	return this.http.post<any>(ENDPOINTS.user.removeUser, null);
	// }

	// updatePassword(values: {
	// 	oldPassword: string;
	// 	newPassword: string;
	// 	confirmNewPassword: string;
	// }): Observable<any> {
	// 	return this.http.post<any>(ENDPOINTS.user.updatePassword, values);
	// }
}
