<div class="drawer-content">
	<mat-nav-list>
		<mat-accordion>
			<mat-list-item
				routerLinkActive="active"
				routerLink="/claims"
				appAppDrawer
			>
				<mat-icon mat-list-icon>shield</mat-icon>
				<div class="title" mat-line>Maxis</div>
			</mat-list-item>
		</mat-accordion>
	</mat-nav-list>
</div>
