import { Component } from '@angular/core';
import { LoadingService } from '../../services/loading.service';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  standalone: true,
  imports: [MatProgressSpinnerModule, CommonModule]
})
export class LoadingSpinnerComponent {

  public active!: boolean;

  public constructor(private spinner: LoadingService) {
      spinner.loadingSubject.subscribe((status: boolean) => {
          this.active = status;
      });
  }

  ngOnInit(){}

  ngOnDestroy(){
      this.spinner.loadingOff(false);
  }
}

