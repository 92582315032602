import { Component, Input, inject } from "@angular/core";
import { AuthService } from "../../../authentication/_services/auth.service";
import { UserService } from "../../../authentication/_services/user.service"; 
import { DrawerService } from "../../services/drawer.service";
import { UserProfile } from "../../interfaces/user.interface";
import { MatMenuModule } from "@angular/material/menu";
import { MatChipsModule } from "@angular/material/chips";
import { NgIf } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ['./header.component.scss'],
	standalone: true,
	imports: [
		MatToolbarModule,
		MatIconModule,
		NgIf,
		MatButtonModule,
		MatChipsModule,
		MatMenuModule,
	],
})
export class AppHeaderComponent {
	@Input({ required: true }) userProfile: UserProfile;

	public drawerService = inject(DrawerService);
	public userService = inject(UserService);
	public authService = inject(AuthService);
}
