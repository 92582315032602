import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms";
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from "@angular/material/bottom-sheet";
import { AlertService } from "src/app/share/services/alert.service";
import { ResetPasswordValidators } from "src/app/share/utils/validators/reset-password-validators";
import { LoadingService } from "src/app/share/services/loading.service";
import { UserService } from "src/app/authentication/_services/user.service";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatDividerModule } from "@angular/material/divider"
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from '@angular/material/form-field';
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
  standalone: true,
  imports: [
    MatIconModule,
    MatFormFieldModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatToolbarModule,
    CommonModule
  ]
})
export class ChangePasswordComponent implements OnInit {
  hide: boolean = true;
  formGroup!: FormGroup;
  submitted: boolean = false;

  newPassLength: boolean = false;
  repeatNewPassLength: boolean = false;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<ChangePasswordComponent>,
    private loadingService: LoadingService,
    private alertService: AlertService,
    private userDataService: UserService,
    @Inject(MAT_BOTTOM_SHEET_DATA) data: any
  ) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup(
      {
        currentPassword: new FormControl(null, [Validators.required]),
        newPassword: new FormControl(
          null,
          Validators.compose([
            Validators.required,
            Validators.minLength(8),
            ResetPasswordValidators.patternValidator(
              new RegExp("(?=.*[0-9])"),
              {
                requiresDigit: true,
              }
            ),
            ResetPasswordValidators.patternValidator(
              new RegExp("(?=.*[a-z])"),
              {
                requiresLowercase: true,
              }
            ),
            ResetPasswordValidators.patternValidator(
              new RegExp("(?=.*[$@^!%*?&])"),
              {
                requiresSpecialChars: true,
              }
            ),
          ])
        ),
        repeatNewPassword: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
        ]),
      },
      {
        validators: ResetPasswordValidators.MatchValidator,
      }
    );
  }

  submit() {
    this.loadingService.loadingOn(true);
    this.submitted = true;
    let obj = {
      oldPassword: this.formGroup.value.currentPassword,
      newPassword: this.formGroup.value.newPassword,
      confirmNewPassword: this.formGroup.value.repeatNewPassword,
    };

    // this.userDataService.updatePassword(obj).subscribe({
    //   next: (x: any) => {
    //     this.loadingService.loadingOff(true);
    //     this.bottomSheetRef.dismiss(true);
    //     this.alertService.openSnackBar("Successfully updated account password");
    //   },
    //   error: (err: any) => {
    //     this.loadingService.loadingOff(true);
    //     if (err.error) {
    //       this.alertService.openSnackBar(`Error: ${err.error}`);
    //     } else {
    //       this.alertService.openSnackBar(`Error updating account password`);
    //     }
    //   },
    // });
  }

  close(event: MouseEvent): void {
    this.bottomSheetRef.dismiss();
    event.preventDefault();
  }

  get f() {
    return this.formGroup.controls;
  }

  get requiredValid() {
    return !this.formGroup.controls["newPassword"].hasError("required");
  }

  getNewPasswordLength(value: any) {
    if (value.length < 8) {
      this.newPassLength = false;
    } else {
      this.newPassLength = true;
    }
  }

  getRepeatNewPasswordLength(value: any) {
    if (value.length < 8) {
      this.repeatNewPassLength = false;
    } else {
      this.repeatNewPassLength = true;
    }
  }
}
