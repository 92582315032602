import { Directive, HostListener, inject } from "@angular/core";
import { DrawerService } from "../../services/drawer.service";

@Directive({
	selector: "[appAppDrawer]",
	standalone: true,
})
export class AppDrawerDirective {
  private drawerService = inject(DrawerService)

	@HostListener("click", []) onClick() {
		if (window.innerWidth <= 768 && this.drawerService.drawer.opened) {
			this.drawerService.drawer.close();
		}
	}
}
