import { enableProdMode, importProvidersFrom } from "@angular/core";
import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { provideAnimations } from "@angular/platform-browser/animations";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { HttpRequestInterceptor } from "src/app/share/services/http.interceptor";
import {
	HTTP_INTERCEPTORS,
	withInterceptorsFromDi,
	provideHttpClient,
} from "@angular/common/http";
import { MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatBottomSheetModule } from "@angular/material/bottom-sheet";
// import {NgxMaskModule} from 'ngx-mask'
import { provideRouter } from "@angular/router";
import { APP_ROUTES }  from "src/app/app-routing.module";
import { provideEnvironmentNgxMask } from "ngx-mask";

export function getBaseUrl() {
	return document.getElementsByTagName("base")[0].href;
}

const providers = [
	importProvidersFrom(
		BrowserModule,
		MatSnackBarModule,
		MatBottomSheetModule,
		ServiceWorkerModule.register("/ngsw-worker.js", {
			enabled: environment.production,
		}),
	),
  	provideEnvironmentNgxMask(),
	provideRouter(APP_ROUTES),
	{ provide: MAT_DATE_LOCALE, useValue: "en-MY" },
	{
		provide: HTTP_INTERCEPTORS,
		useClass: HttpRequestInterceptor,
		multi: true,
	},
	provideAnimations(),
	provideHttpClient(withInterceptorsFromDi()),
	{ provide: "BASE_URL", useFactory: getBaseUrl, deps: [] },
	importProvidersFrom(MatNativeDateModule)
];

if (environment.production) {
	enableProdMode();
}

bootstrapApplication(AppComponent, { providers }).catch((err) =>
	console.log(err),
);
