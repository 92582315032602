import { Component, OnInit } from "@angular/core";
import { Router, RouterLinkActive, RouterLink } from "@angular/router";
import { AuthService } from "../../../authentication/_services/auth.service";
import { UserService } from "../../../authentication/_services/user.service";
import { MatLineModule } from "@angular/material/core";
import { MatIconModule } from "@angular/material/icon";
import { AppDrawerDirective } from "../directives/app-drawer.directive";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";

@Component({
    selector: "app-drawer",
    templateUrl: "./drawer.component.html",
    styleUrls: ["./drawer.component.scss", "../full-layout.scss"],
    standalone: true,
    imports: [
        MatListModule,
        MatExpansionModule,
        RouterLinkActive,
        RouterLink,
        AppDrawerDirective,
        MatIconModule,
        MatLineModule,
    ],
})
export class AppDrawerComponent implements OnInit {
  tab: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    if (this.router.url.includes("motor")) {
      this.tab = "motor";
    }
    if (this.router.url.includes("settings")) {
      this.tab = "settings";
    }
    if (this.router.url.includes("report")) {
      this.tab = "reports";
    }
    if (this.router.url.includes("accounts")) {
      this.tab = "accounts";
    }
    if (this.router.url.includes("rider-accident")) {
      this.tab = "rider-accident";
    }
    if (this.router.url.includes("ecommerce")) {
      this.tab = "ecommerce";
    }
  }

  logout() {
    this.authService.logout();
  }
}
