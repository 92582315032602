import { inject } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from "@angular/router";
import { AuthService } from "./auth.service";

export const authGuard = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
) => {
	const router = inject(Router);
	const authService = inject(AuthService);

	if (!authService.isLoggedIn()) {
		router.navigate(["/authentication/login"], {
			queryParams: { redirectUrl: state.url },
		});
	}
	return authService.isLoggedIn();
};
